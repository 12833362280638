<template>
  <div class="auth">
    <div style="background-color: #fff; position: relative; height: 100vh">
      <div
        style="
          font-size: 16px;
          color: #333;
          position: fixed;
          top: 30%;
          right: 0;
          left: 0;
          margin: auto;
        "
        class="loging"
      >
        <img
         src="@assets/img/redirect-load.gif"
        />
        <p>loading..</p>
        <!-- 参展获取更多商机OidcCallback页面 -->
      </div>
      <div style="position: fixed; bottom: 16%; width: 100%">
        <div>
          <img
            src="@assets/img/redirect-2.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "redirectUrl",
  computed: {
    ...mapGetters({
      login: "getLogin",
      exhibitionId: "getExhibitionId",
      redirectUrl: "getRedirectUrl",
    }),
  },
  data() {
    return {};
  },
  created() {
    const url = this.redirectUrl || location.origin + "/home/index/" + this.exhibitionId;
    this.setLoginType("");
    if (!this.login && this.redirectUrl.indexOf("/user/") !== -1) {
      location.href = location.origin + "/home/index/" + this.exhibitionId;
    } else {
      location.href = url;
    }
  },
  methods: {
    ...mapMutations({
      setLoginType: "getLoginType",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
div {
  text-align: center;
  img {
    display: inline-block;
    // height: 30px;
    max-width: 140px;
  }
}
.loging {
  img {
    width: 120px;
    height: 120px;
  }
}
</style>